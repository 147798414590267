export const data = [
  {
    id: 1,
    img: "../images/collage.png",
    title: "FRAME YOUR WORLD",
    desc: "Transform Your Memories into Timeless Treasures!",
  }
];


export const faqs = [
  {
    id: 1,
    question:
      "What type of frames do you offer?",
    answer:
      "We offer a variety of frames, including metal, wood, and plastic frames in various styles and colors.",
  },
  {
    id: 2,
    question:
      "Can you frame a custom-sized picture?",
    answer:
      "Yes, we offer custom framing services for any size picture.",
  },
  {
    id: 3,
    question:
      "How much does it cost to frame a picture?",
    answer:
      "The cost of framing a picture depends on various factors, such as the size, type of frame, and glass options. Please visit our website or contact us for more information.",
  },
  {
    id: 4,
    question: "How to contacDo you offer discounts for bulk orders?",
    answer:
      "Yes, we offer discounts for bulk orders. Please contact us for more information.",
  },

  {
    id: 5,
    question: "How long does it take to frame a picture?",
    answer:
      "It typically takes 2-3 business days to frame a picture, but it can vary depending on the complexity of the project and our workload.",
  },

  {
    id: 6,
    question: "Can I order a frame online and have it shipped to me?",
    answer:
      "Yes, you can order a frame online and have it shipped to you.",
  },

  {
    id: 7,
    question: "Do you offer framing services for artwork or just photographs?",
    answer:
      "We offer framing services for both artwork and photographs.",
  },

  {
    id: 8,
    question: "What is your return policy for framed pictures?",
    answer:
      "We accept returns for framed pictures within 30 days of purchase. Please visit our website or contact us for more information.",
  },

  {
    id: 9,
    question: "What is the process for ordering a custom frame?",
    answer:
      "To order a custom frame, you can either visit our website or contact us directly. We will guide you through the process of selecting the frame, glass, and other options.",
  },

  {
    id: 10,
    question: "How do I measure the size of my artwork for framing?",
    answer:
      "To measure the size of your artwork for framing, measure the width and height of the artwork, and add a few extra millimeters or inches to ensure a proper fit.",
  }
];


export const vids = [
  {
    id: 1,
    img: "../images/collage.png",
    title: "FRAME ANYTHING",
    desc: "Whether it’s something from your closet or a photo on your phone, we can frame it",
  }
];


export const testimonials = [
  {
    id: 1,
    imgLink: "https://images.pexels.com/photos/6000065/pexels-photo-6000065.jpeg?auto=compress&cs=tinysrgb&w=400",
    testimony: "I recently had my artwork framed by The Kwame Inc Frames, and I couldn't be happier with the result. The attention to detail and craftsmanship are exceptional. My piece looks stunning, and the frame perfectly complements the artwork.",
    contributor: "Josephine F Kwakye",
    occupation: "Software Developer"
  },

  {
    id: 2,
    imgLink: "https://images.pexels.com/photos/5792641/pexels-photo-5792641.jpeg?auto=compress&cs=tinysrgb&w=400",
    testimony: "I entrusted them with framing a family portrait, and they exceeded my expectations.The team helped me choose the ideal frame that enhanced the photograph beautifully and the final product is now proudly displayed in our home. Thank you, The Kwame Inc Frames!",
    contributor: "Michael Reynolds",
    occupation: "Entrepreneur"
  },

  {
    id: 3,
    imgLink: "https://images.pexels.com/photos/5615665/pexels-photo-5615665.jpeg?auto=compress&cs=tinysrgb&w=420&h=400&dpr=1",
    testimony: "The team understood the sentimental value of the artwork I brought in and treated it with utmost care. They provided valuable suggestions for frame selection, and the end result was breathtaking. I am grateful for their expertise and professionalism.",
    contributor: "Abena Mary",
    occupation: "Business Woman"
  }
]

export const tos = [
  {
    id: 1,
    title: "General Terms",
    body: "By using our services, you confirm that you are at least 18 years old or have parental/guardian consent.We reserve the right to update these terms at any time without prior notice. Continued use of our services after changes to these terms constitutes acceptance of the updated terms."
  },

  {
    id: 2,
    title: "Products & Orders",
    body: "We strive to display accurate product information, but slight variations in color and dimensions may occur. All orders are subject to availability. If a product is out of stock, we will notify you and provide alternatives or a refund. We reserve the right to cancel orders suspected of fraudulent activity."
  },

  {
    id: 3,
    title: "Pricing & Payment",
    body: "Prices are listed in cedis and are subject to change without notice. Payment must be made in full before orders are processed. We accept payments through mobile money, cash etc. Any applicable taxes or fees will be added to your total at checkout."
  },

  {
    id: 4,
    title: "Shipping & Delivery",
    body: "We aim to process and ship orders within 2 weeks. Delivery times may vary based on location and carrier delays. Customers are responsible for providing accurate shipping information. We are not liable for lost packages due to incorrect addresses. International shipping may be subject to customs duties and taxes, which are the buyer’s responsibility."
  },

  {
    id: 5,
    title: "Returns & Refunds",
    body: "We accept returns within [Number] days of purchase, provided the item is in its original condition. Customers are responsible for return shipping costs unless the item is defective or incorrect. Refunds will be issued to the original payment method once the return is processed."
  },

  {
    id: 6,
    title: "Warranty & Liability",
    body: "Our frames are crafted with care, but we do not provide warranties beyond what is required by law. We are not liable for damage caused by misuse, accidents, or improper handling of our products."
  }
]